import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import getImageData from "./ImageData";
import '../App.css';
import React from "react";

function slider() {
    return(
        <div className="imageslider-height"><ImageGallery items={getImageData()} /></div>);
}

export default slider