import React from "react";
import {Container, Image} from "react-bootstrap";
import '../App.css';
import background from "../assets/Suederstrasse.png";
import Imageslider from "./Imageslider";
import MapContainer from "./Map";
import {Link} from "react-router-dom";
import Icon1 from "../assets/icons/p_personen.gif"
import Icon2 from "../assets/icons/p_m2.gif"
import Icon3 from "../assets/icons/p_schlafraeume.gif"
import Icon4 from "../assets/icons/p_UENDR.gif"
import Icon5 from "../assets/icons/p_BPAPL.gif"
import Icon6 from "../assets/icons/p_UTERR.gif"
import Icon7 from "../assets/icons/p_UGESP.gif"
import Icon8 from "../assets/icons/p_UNIRZ.gif"
import Icon9 from "../assets/icons/p_UHTAA.gif"
import Icon10 from "../assets/icons/p_LZMBL.gif"
import Icon11 from "../assets/icons/p_BWWWZ.gif"
import Icon12 from "../assets/icons/p_BFRAB.gif"
import Icon13 from "../assets/icons/p_UBALK.gif"
import Icon14 from "../assets/icons/p_BWMAS.gif"


function Unterkunft() {
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <Imageslider/>
                                Die Unterkunft verteilt sich über UG, EG und 1.OG.
                            </div>
                        </div>
                        <Container className="gx-0">
                            <div className="row gx-0">
                                <div className="col-md-8 gx-0">
                                    <h4 className="me-lg-5">
                                        Allgemeine Beschreibung
                                    </h4>
                                    <p className="pe-lg-5">
                                        Süderstraße Gartenhaus<br/>
                                        Süderstraße 9, Wyk<br/>
                                        Das gemütlich eingerichtete Ferienhaus liegt in zentraler, strandnaher Lage am
                                        Rande der Fußgängerzone.<br/>
                                        Die Entfernung zum Strand beträgt ca. 150 m. Das Meerwasserwellenbad,
                                        Kurmittelhaus, Gaststätten,Umweltzentrum, Trimmpfad und
                                        zahlreiche Einkaufsmöglichkeiten sind in wenigen Gehminuten zu erreichen. <br/>
                                        Im
                                        Erdgeschoss befindet sich eine separate Küche mit direktem
                                        Zugang zu einer Sonnenterrasse. Außerdem ein großzügiges Wohn/ Esszimmer und ein
                                        Badezimmer mit großer Dusche, Toilette, Waschtisch und Waschmaschine. Im
                                        Obergeschoss ist ein zweites Bad mit Dusche, Bidet und WC. Hier befindet sich
                                        auch ein
                                        Schlafzimmer mit zwei Einzelbetten. <br/>
                                        Im Untergeschoss ist ein weiteres
                                        Schlafzimmer mit einem Doppelbett und einer separaten Sauna. Das Feriendomizil
                                        hat eine Größe
                                        von ca.60 m² Dachschrägen!
                                    </p>
                                </div>
                                <div className="col-md-4 gx-0">
                                    <h4>
                                        Lage
                                    </h4>
                                    <table className="table table-borderless ">
                                        <thead>
                                        <th>Entfernungen (zirka)</th>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className="p-0">
                                                Zum Bäcker
                                            </td>
                                            <td className="p-0">
                                                100 m
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="p-0">
                                                zur Einkaufsmöglichkeit
                                            </td>
                                            <td className="p-0">
                                                300 m
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="p-0">
                                                zum Hafen
                                            </td>
                                            <td className="p-0">
                                                500 m
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="p-0">
                                                zum Kurzentrum
                                            </td>
                                            <td className="p-0">
                                                500 m
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="p-0">
                                                zum Meer
                                            </td>
                                            <td className="p-0">
                                                200 m
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="p-0">
                                                zum ÖPNV
                                            </td>
                                            <td className="p-0">
                                                300 m
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="p-0">
                                                zur Gaststätte
                                            </td>
                                            <td className="p-0">
                                                100 m
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="p-0">
                                                zum Wellenbad
                                            </td>
                                            <td className="p-0">
                                                500 m
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <MapContainer/>
                            </div>
                        </Container>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page-header">
                                    <h4>
                                        Ausstattung und Merkmale
                                    </h4>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <dl>
                                            <dt>
                                                Auszeichnung
                                            </dt>
                                            <dd>
                                                <p className="mb-1">Haustiere auf Anfrage gestattet*</p>
                                                <p className="mb-1">Nichtraucher</p>
                                            </dd>
                                            <dt>
                                                Außenbereich
                                            </dt>
                                            <dd>
                                                <p className="mb-1">Gartenmöbel</p>
                                                <p className="mb-1">Terrasse</p>
                                                <p className="mb-1">Unterstellmögl. für Fahrräder</p>
                                            </dd>
                                            <dt>
                                                Küche / Haushalt
                                            </dt>
                                            <dd>
                                                <p className="mb-1">Backofen</p>
                                                <p className="mb-1">Geschirrspüler</p>
                                                <p className="mb-1">Waschmaschine</p>
                                            </dd>
                                        </dl>
                                        <p style={{fontStyle: "italic"}}>* mit Extrakosten verbunden</p>
                                    </div>
                                    <div className="col-md-4">
                                        <dl>
                                            <dt>
                                                Service
                                            </dt>
                                            <dd>
                                                <p className="mb-1">Bettwäsche/Handtücher</p>
                                                <p className="mb-1">Endreinigung*</p>
                                            </dd>
                                            <dt>
                                                <p className="mb-1">Unterhaltung / Kommunik.</p>
                                            </dt>
                                            <dd>
                                                <p className="mb-1">Kabel-TV</p>
                                                <p className="mb-1">Radio</p>
                                                <p className="mb-1">WLAN-Zugang</p>
                                            </dd>
                                        </dl>
                                    </div>
                                    <div className="col-md-4">
                                        {/*TODO: Legende einbauen*/}
                                        <table className="table table-striped">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <Image className="iconImage"
                                                        src={Icon1}
                                                    />
                                                </td>
                                                <td className="iconText noUni">
                                                    4
                                                </td>
                                                <td>
                                                    <Image className="iconImage"
                                                        src={Icon8}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    ✓
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Image className="iconImage"
                                                        src={Icon2}
                                                    />
                                                </td>
                                                <td className="iconText noUni">
                                                    60
                                                </td>
                                                <td>
                                                    <Image className="iconImage"
                                                        src={Icon9}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    ✓
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Image className="iconImage"
                                                        src={Icon3}
                                                    />
                                                </td>
                                                <td className="iconText noUni">
                                                   2
                                                </td>
                                                <td>
                                                    <Image className="iconImage"
                                                        src={Icon10}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    —
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Image className="iconImage"
                                                        src={Icon4}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    ✓
                                                </td>
                                                <td>
                                                    <Image className="iconImage"
                                                        src={Icon11}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    ✓
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <Image className="iconImage"
                                                    src={Icon5}
                                                />
                                                </td>
                                                <td className="iconText">
                                                    —
                                                </td>
                                                <td>
                                                    <Image className="iconImage"
                                                        src={Icon12}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    —
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <Image className="iconImage"
                                                    src={Icon6}
                                                />
                                                </td>
                                                <td className="iconText">
                                                    ✓
                                                </td>
                                                <td>
                                                    <Image className="iconImage"
                                                        src={Icon13}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    —
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <Image className="iconImage"
                                                    src={Icon7}
                                                />
                                                </td>
                                                <td className="iconText">
                                                    ✓
                                                </td>
                                                <td>
                                                    <Image className="iconImage"
                                                        src={Icon14}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    ✓
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <Container className="gx-0">
                                    <div className="row gx-0">
                                        <div className="col-md-8 gx-0">
                                            <h4 className="me-lg-5">
                                                Raumaufteilung
                                            </h4>
                                            <div className="row gx-0 pe-lg-5">
                                                <div className="col-md-6 gx-0">
                                                    <p>2 Schlafräume</p>
                                                    <p>2 Badezimmer mit Dusche</p>
                                                </div>
                                                <div className="col-md-6 gx-0">
                                                    <p>1 Wohnraum</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 gx-0">
                                            <h4>
                                                Betten
                                            </h4>
                                            <p>2 Einzelbetten</p>
                                            <p>1 Doppelbett</p>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Unterkunft