import logo from './assets/logo.svg';
import './App.css';
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, useLocation
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import {Navbar, Container, Nav, Row, Col} from "react-bootstrap";
import Suederstrasse from "./suederstrasse/Main";
import Sandwall from "./sandwall/Main";
import Manage from "./Manage";
import Landing from "./Landing";


import SimpleReactLightbox from 'simple-react-lightbox'
import Impressum from "./Impressum";

const excludedRoutes = ["/"]

function MyNavbar() {
    const location = useLocation();
    return (
        <div>
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand><Link to="/"  style={{textDecoration: "none", color: "initial"}}>Jost Ferienwohnungen</Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link><Link className={`nav-link ${location.pathname === "/sandwall" ? "active" : ""}`}
                                            to="/sandwall">Strandperle
                                Sandwall</Link></Nav.Link>
                            <Nav.Link><Link className={`nav-link ${location.pathname === "/suederstrasse" ? "active" : ""}`} to="/suederstrasse">Süderstraße
                                Gartenhaus</Link></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

function App() {
    return (
        <Router>
            <MyNavigation/>
        </Router>
    );
}


function MyNavigation() {
    const location = useLocation();
    return (
        <div>
            {!excludedRoutes.includes(location.pathname) && <MyNavbar/>}
            <SimpleReactLightbox>
                <Switch>
                    <Route path="/sandwall">
                        <Sandwall/>
                    </Route>
                    <Route path="/impressum">
                        <Impressum/>
                    </Route>
                    <Route path="/manage">
                        <Manage/>
                    </Route>
                    <Route path="/suederstrasse">
                        <Suederstrasse/>
                    </Route>
                    <Route path="/">
                        <Landing/>
                    </Route>
                </Switch>
            </SimpleReactLightbox>
        </div>
    );

}


export default App;
