import React, {useEffect, useState} from "react";
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";

function MapContainer(props) {
    const [google, setGoogle] = useState();
    return (
        <div style={{position: "relative", width: "100%", height: "15vh"}}>
            <GoogleProvider onChange={(google) => setGoogle(google)}/>
            <Map google={google}
                 initialCenter={{
                     lat: 54.689027,
                     lng: 8.570583
                 }}
                 zoom={13}
            >
                <Marker
                    title={'Süderstraße Gartenhaus'}
                    position={{
                        lat: 54.689027,
                        lng: 8.570583
                    }}/>
            </Map>
        </div>
    );
}

function Wrapper(props) {
    useEffect(() => {
        props.onChange(props.google);
    }, [props.google]);

    return null;
}

const GoogleProvider = GoogleApiWrapper({
    apiKey: "AIzaSyBThdP3Q6_jfrfiTKUj5BvcHan8JhBExUQ",
})(Wrapper);

export default MapContainer;