import Imageslider from "./Imageslider";
import {Button, Col, Container, Form, Image, InputGroup, Row, Table} from "react-bootstrap";
import MapContainer from "./Map";
import React, {useState} from "react";
import getImageData from "./ImageData";

function PriceTab() {
    const s1Price = 100;
    const s2Price = 120;
    const s3Price = 100;

    const [calendarDate, setCalendarDate] = useState(new Date());
    const [nights, setNights] = useState(null)
    const [people, setPeople] = useState(null);
    const [validated, setValidated] = useState(false);
    const [validForm, setValidForm] = useState(false);
    const [price, setPrice] = useState(0)

    const getPrice = () => {
        let month = new Date(calendarDate).getMonth()
        let dailyPrice=0;
        if(month<4){
            dailyPrice=s1Price;
        } else if(month<9) {
            dailyPrice=s2Price;
        } else {
            dailyPrice=s3Price;
        }
        if(people>2){
            dailyPrice+=(people-2)*10
        }
        setPrice(dailyPrice*nights)
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity() === true){
            setValidForm(true)
            getPrice();
        } else{
            setValidForm(false)
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <Container className="gx-0">
                        <div className="row gx-0">
                            <div className="col-md-12 gx-0">
                                <h4 className="me-lg-5">
                                    Preisberechnung
                                </h4>
                                <Form className="me-lg-5" noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Row className="mb-3 align-items-end">
                                        <Form.Group as={Col} md="3">
                                            <Form.Label>Anreise</Form.Label>
                                            <Form.Control
                                                required
                                                type="date"
                                                value={calendarDate}
                                                onChange={e => setCalendarDate(e.target.value )}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="3">
                                            <Form.Label>Nächte</Form.Label>
                                            <Form.Control
                                                required
                                                type="number"
                                                placeholder="Anzahl Nächte..."
                                                value={nights}
                                                onChange={e => setNights(e.target.value )}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label>Personen</Form.Label>
                                            <Form.Control
                                                required
                                                type="number"
                                                placeholder="Anzahl Personen..."
                                                value={people}
                                                onChange={e => setPeople(e.target.value )}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="2">
                                            <Button type="submit" style={{width: "100%"}}>Berechnen</Button>
                                        </Form.Group>
                                    </Row>
                                </Form>
                                {validForm ? "Der Preis für diese Buchung würde "+price+" € betragen." : null}
                            </div>
                        </div>
                        <div className="row gx-0">
                            <div className="col-md-12 gx-0">
                                <h4 className="me-lg-5">
                                    {/*Saisionale Mietpreise*/}
                                    Saisionale Mietpreise
                                </h4>
                                <div className="me-lg-5">
                                    <Table striped responsive="sm">
                                        <thead>
                                        <tr>
                                            <th>Zeitraum</th>
                                            <th>Preis/Nacht</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        <tr>
                                            <td>01.01. bis 30.04.</td>
                                            <td>{s1Price} €</td>
                                        </tr>

                                        <tr>
                                            <td>01.05. bis 30.09.</td>
                                            <td>{s2Price} €</td>
                                        </tr>

                                        <tr>
                                            <td>01.10. bis 31.12.</td>
                                            <td>{s3Price} €</td>
                                        </tr>

                                        </tbody>
                                    </Table>
                                    Alle Preise für 2 Personen.
                                    Pro weitere erwachsene Person zusätzlich 10€/Nacht.<br/>
                                    Mindestmietdauer 5 Tage. Abweichung nach Absprache möglich.<br/>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-0">
                            <div className="col-md-12 gx-0">
                                <h4 className="me-lg-5">
                                    Optionale Zusatzleistungen
                                </h4>
                                <div className="me-lg-5">
                                    <Table striped responsive="sm">
                                        <thead>
                                        <tr>
                                            <th style={{width: "33%"}}>Leistung</th>
                                            <th style={{width: "33%"}}>Preis</th>
                                            <th style={{width: "33%"}}>Kommentar</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Haustier</td>
                                            <td>€&nbsp;5,00</td>
                                            <td>pro Haustier und Nacht</td>
                                        </tr>

                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default PriceTab;