import background from "./assets/Suederstrasse.png";
import React from "react";
import {Image, Tabs} from "react-bootstrap";
import {Tab} from "bootstrap";
import {Link} from "react-router-dom";
import IG_Logo from "./assets/ig_logo.png";
import SandwallImage from './assets/sandwall/1.jpg';
import SuederstrasseImage from './assets/suederstrasse/1.jpg';


function Main() {
    return (
        <div>
            <div className="d-flex justify-content-center py-lg-5 backgroundHeader"
                 style={{backgroundImage: `url(${background})`}}>
                <div className="mx-auto">
                    <h1 className="navHeadline">Jost Ferienwohnungen</h1>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="text-center display-6">
                            Unsere Ferienunterkünfte
                        </h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Link to={"/sandwall"} style={{textDecoration: "none"}}>
                            <Image style={{maxHeight: "100%", cursor: "pointer"}}
                                   src={SandwallImage}
                                   layout="fill"
                                   rounded
                                   fluid
                            />
                            <h4 className="text-center mt-3 h3" style={{borderBottom: "none"}}>Strandperle Sandwall</h4>
                        </Link>
                    </div>
                    <div className="col-md-6">
                        <Link to={"/suederstrasse"} style={{textDecoration: "none"}}>
                            <Image style={{maxHeight: "100%", cursor: "pointer"}}
                                   src={SuederstrasseImage}
                                   layout="fill"
                                   rounded
                                   fluid
                            />
                            <h4 className="text-center mt-3 h3" style={{borderBottom: "none"}}>Süderstraße
                                Gartenhaus</h4>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main