import React, {useEffect, useState} from 'react';
import Calendar from 'react-calendar';
import {Container} from "react-bootstrap";
import 'react-calendar/dist/Calendar.css';
import './../App.css';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import dayjs from "dayjs";


function CalendarTab() {
    const [dates, setDates] = useState([])

    const fetchDates = async function () {
        const url_response = await fetch("https://jost-foehrurlaub.de/url.json");
        if (!url_response.ok) {
            const message = `An error has occured: ${response.status}`;
            throw new Error(message);
        }
        const url_json = await url_response.json();
        const response = await fetch(url_json["url"]);
        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            throw new Error(message);
        }
        const buchungen = await response.json();
        const dates = buchungen.suederstrasse
        setDates(dates)
    }

    const customFormatTiles = ({date, view}) => {
        let occupiedObj = {
            occupied: false,
            occupiedStart: false,
            occupiedEnd: false,
        }
        for (let dateSet of dates) {
            const start = dayjs(dateSet?.start, "DD.MM.YYYY")
            const end = dayjs(dateSet?.ende, "DD.MM.YYYY")
            if (dayjs(date).isBetween(start, end, null, '[]')) occupiedObj.occupied = true;
            if (dayjs(date).isSame(start)) occupiedObj.occupiedStart = true;
            if (dayjs(date).isSame(end)) occupiedObj.occupiedEnd = true;
        }
        if (!occupiedObj.occupied &&
            !occupiedObj.occupiedStart &&
            !occupiedObj.occupiedEnd) {
            return "vacantCalendarTile";
        } else {
            let returnstr = ""
            if (occupiedObj.occupied) returnstr += "occupied ";
            if (occupiedObj.occupiedStart) returnstr += "occupiedStart ";
            if (occupiedObj.occupiedEnd) returnstr += "occupiedEnd";
            return returnstr;
        }
    }

    useEffect(() => {
        dayjs.extend(customParseFormat)
        dayjs.extend(isBetween);

        async function asyncFetchDates() {
            await fetchDates()
        }

        asyncFetchDates();
    }, [])

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <Container className="gx-0">
                        <div className="row gx-0">
                            <div className="col-md-12 gx-0">
                                <h4 className="me-lg-5">
                                    Belegung der Unterkunft
                                </h4>
                                <div className="gx-0 row">
                                    <div className="col-md-4">
                                        <Calendar
                                            defaultValue={new Date()}
                                            locale={"de-DE"}
                                            tileClassName={customFormatTiles}
                                        />
                                    </div>
                                    <div className="col-md-4 gx-0">
                                        <div className="legende">
                                            <div className="box occupied"><span className="align-middle">Belegt</span></div>
                                            <div className="box vacant"><span className="align-middle">Frei</span></div>
                                            <div className="box today"><span className="align-middle">Heute</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default CalendarTab