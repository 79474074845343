import React from "react";


function Impressum() {
    return (
        <div>
            <div className="d-flex justify-content-center py-lg-5 backgroundHeader">
                <div className="mx-auto">
                    <h1 className="navHeadline">Impressum</h1>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        Angaben gemäß § 5 TMG<br/><br/>

                        Elke Jost<br/>
                        Eichendorffstrasse 4a<br/>
                        63688 Gedern<br/><br/>

                        Mobil 0170 2110854<br/>
                        Email info@jost-foehrurlaub.de<br/>
                        Fax 06045/983542<br/>
                        Festnetz 06045/4848<br/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Impressum