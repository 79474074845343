import Img1 from '../assets/sandwall/1.jpg';
import Img2 from '../assets/sandwall/2.jpg';
import Img3 from '../assets/sandwall/3.jpg';
import Img4 from '../assets/sandwall/4.jpg';
import Img5 from '../assets/sandwall/5.jpg';
import Img6 from '../assets/sandwall/6.jpg';
import Img7 from '../assets/sandwall/7.jpg';
import Img8 from '../assets/sandwall/8.jpg';
import Img9 from '../assets/sandwall/9.jpg';
import Img10 from '../assets/sandwall/10.jpg';
import Img11 from '../assets/sandwall/11.jpg';
import Img12 from '../assets/sandwall/12.jpg';
import Img13 from '../assets/sandwall/13.jpg';

function getImageData() {
    const images = [
        {
            original: Img1,
            thumbnail: Img1,
            description: "Wohnen in erster Reihe mit Meerblick"
        },
        {
            original: Img2,
            thumbnail: Img2,
            description: "Blick vom Hauseingang"
        },
        {
            original: Img3,
            thumbnail: Img3,
            description: "Der Blick aus dem Fenster"
        },
        {
            original: Img4,
            thumbnail: Img4,
            description: "Gemütlicher Wohnbereich mit Meerblick, großem Flat TV, Internet Wlan und Wlan Radio"
        },
        {
            original: Img5,
            thumbnail: Img5,
            description: "Integrierter Essbereich"
        },
        {
            original: Img6,
            thumbnail: Img6,
            description:"Einbauküche mit Geschirrspüler, Backofen, Kühlgerät, Toaster, Wasserkocher und Kaffeemaschine."
        },
        {
            original: Img7,
            thumbnail: Img7,
            description: "Angenehm ruhen im Schlafzimmer mit Doppelbett und Kleiderschrank."
        },
        {
            original: Img8,
            thumbnail: Img8,
            description: "Ein zweites Schlafzimmer zum Gartenbereich."
        },
        {
            original: Img9,
            thumbnail: Img9,
            description: "Badezimmer mit Toilette, Badewanne,Bidet und Waschtisch."
        },
        {
            original: Img10,
            thumbnail: Img10,
            description: "Ein zweites Badezimmer mit Duschvorrichtung, WC und Waschtisch um morgens Stauzeiten zu vermeiden."
        },
        {
            original: Img11,
            thumbnail: Img11,
            description: "zweites Badezimmer"
        },
        {
            original: Img12,
            thumbnail: Img12,
            description: "Großer Flur mit Garderobe, Spiegel und Schuhablage."
        },
        {
            original: Img13,
            thumbnail: Img13,
            description: "Grundriss und nicht maßstabsgetreu, Änderungen unter Vorbehalt. "
        },
    ]
    return images;
}

export default getImageData