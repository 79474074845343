import background from "./assets/Sandwall.png";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";

function Manage() {

    const [buchungen, setBuchungen] = useState({})
    const [startDate, setStartDate] = useState({})
    const [endDate, setEndDate] = useState({})
    const [wohnung, setWohnung] = useState()
    const [jsonText, setJsonText] = useState("")
    const [validated, setValidated] = useState(false)
    const [show, setShow] = useState(false)


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        dayjs.extend(customParseFormat)
        // dayjs.extend(isBetween);

        async function asyncFetchDates() {
            await fetchDates()
        }

        asyncFetchDates();
    }, [])


    const fetchDates = async function () {
        const url_response = await fetch("https://jost-foehrurlaub.de/url.json");
        if (!url_response.ok) {
            const message = `An error has occured: ${response.status}`;
            throw new Error(message);
        }
        const url_json = await url_response.json();
        const response = await fetch(url_json["url"]);
        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            throw new Error(message);
        }
        const buchungen = await response.json();
        for(let key of Object.keys(buchungen)){
            let myArr = buchungen[key]
            myArr.sort((a,b)=>(dayjs(a.start,"DD.MM.YYY")>dayjs(b.start,"DD.MM.YYY")) ? 1 : -1)
            buchungen[key] = myArr;
        }
        setBuchungen(buchungen)
        setWohnung(Object.keys(buchungen)[0])
    }

    const generateColumns = (() => {
        if (!buchungen) return <div/>
        let colsize = Math.floor(12 / (Object.keys(buchungen).length))
        let className = "col-md-" + colsize.toString();
        return Object.keys(buchungen).map((house) => {
            return (
                <div key={house} className={"justify-content-center " + className} align={"center"}>
                    <h2 className={"mb-3"}>{house}</h2>
                    {generateDates(house)}
                </div>
            )
        })
    });

    const generateDates = ((house) => {
        return buchungen[house].map((date, index) => {
            return (
                <div key={index} style={{width: "fit-content"}}>{date.start + " - " + date.ende}
                    <div
                        style={{
                            display: "inline",
                            cursor: "pointer",
                            fontWeight: 400,
                            fontFamily: "apple color emoji,segoe ui emoji,noto color emoji,android emoji,emojisymbols,emojione mozilla,twemoji mozilla,segoe ui symbol"
                        }}
                        onClick={() => removeDate(house, index)}
                    >
                        🗑
                    </div>
                </div>
            )
        })
    });

    const removeDate = ((house, index) => {
        let newBuchungen = Object.assign({}, buchungen);
        newBuchungen[house].splice(index,1)
        // newBuchungen[house].pop(index)
        setBuchungen(newBuchungen)
    })

    const handleSubmit = ((event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity() === true) {
            let dateObj = {}
            dateObj["start"] = dayjs(startDate).format("DD.MM.YYYY");
            dateObj["ende"] = dayjs(endDate).format("DD.MM.YYYY");
            let newBuchungen = Object.assign({}, buchungen)
            newBuchungen[wohnung].push(dateObj)
            setBuchungen(newBuchungen)
        }
    })

    return (
        <div>
            <div className="d-flex justify-content-center py-lg-5 backgroundHeader"
                 style={{backgroundImage: `url(${background})`}}>
                <div className="mx-auto">
                    <h1 className="navHeadline">Verwalten</h1>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <Form className="me-lg-5" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="mb-3 align-items-end">
                            <Form.Group as={Col} md="3">
                                <Form.Label>Anreise</Form.Label>
                                <Form.Control
                                    required
                                    type="date"
                                    value={startDate}
                                    onChange={e => setStartDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Abreise</Form.Label>
                                <Form.Control
                                    required
                                    type="date"
                                    value={endDate}
                                    onChange={e => setEndDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Wohnung</Form.Label>
                                <Form.Select
                                    required
                                    onChange={(e) => setWohnung(e.target.value)}
                                    value={wohnung}
                                >
                                    {Object.keys(buchungen).map((wohnung) => {
                                        return (
                                            <option value={wohnung}>{wohnung}</option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                                <Button type="submit" style={{width: "100%"}}>Hinzufügen</Button>
                            </Form.Group>
                        </Row>
                    </Form>
                    {generateColumns()}
                </div>
                <div className="row justify-content-center" align={"center"}>
                    <Button type="submit" style={{width: "30%"}} onClick={(e) => {
                        e.preventDefault();
                        setJsonText(JSON.stringify(buchungen, null, 2))
                        handleShow()
                    }}>Exportieren</Button>
                </div>
            </div>


            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="vw-100"
                style={{maxWidth: "100%"}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>JSON</Modal.Title>
                </Modal.Header>
                <Modal.Body> <Form.Control as="textarea" readonly value={jsonText}/></Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        navigator.clipboard.writeText(jsonText)
                    }}>
                        Kopieren
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal>


        </div>
    );
}

export default Manage