import Img1 from '../assets/suederstrasse/1.jpg';
import Img2 from '../assets/suederstrasse/2.jpg';
import Img3 from '../assets/suederstrasse/3.jpg';
import Img4 from '../assets/suederstrasse/4.jpg';
import Img5 from '../assets/suederstrasse/5.jpg';
import Img6 from '../assets/suederstrasse/6.jpg';
import Img7 from '../assets/suederstrasse/7.jpg';
import Img8 from '../assets/suederstrasse/8.jpg';
import Img9 from '../assets/suederstrasse/9.jpg';
import Img10 from '../assets/suederstrasse/10.jpg';
import Img11 from '../assets/suederstrasse/11.jpg';
import Img12 from '../assets/suederstrasse/12.jpg';
import Img13 from '../assets/suederstrasse/13.jpg';
import Img14 from '../assets/suederstrasse/14.jpg';
import Img15 from '../assets/suederstrasse/15.jpg';
import Img16 from '../assets/suederstrasse/16.jpg';
import Img17 from '../assets/suederstrasse/17.jpg';
import Img18 from '../assets/suederstrasse/18.jpg';
import Img19 from '../assets/suederstrasse/19.jpg';

function getImageData() {
    const images = [
        {
            original: Img1,
            thumbnail: Img1,
            description: "Stadthaus in zentraler Lage"
        },
        // {
        //     original: Img2,
        //     thumbnail: Img2,
        //     description: "Gemütliches Sofa für angenehme Stunden."
        // },
        {
            original: Img3,
            thumbnail: Img3,
            description: "Einbauküche mit Geschirrspüler, Backofen, Kühlgerät, Toaster, Wasserkocher und Kaffeemaschine. Direkter Zugang zur Innenhof Terrasse."
        },
        {
            original: Img4,
            thumbnail: Img4,
            description: "Wohn- und Essbereich"
        },
        {
            original: Img5,
            thumbnail: Img5,
            description: "Badezimmer mit großer Dusche, Waschtisch,Toilette und Waschmaschine."
        },
        {
            original: Img12,
            thumbnail: Img12,
            description: "Badezimmer mit Waschmaschine."
        },
        // {
        //     original: Img6,
        //     thumbnail: Img6,
        //     description: "Eingangsbereich."
        // },
        {
            original: Img7,
            thumbnail: Img7,
            description: "Ein zweites Badezimmer um morgens Stauzeiten zu vermeiden mit Dusche, WC, Bidet und Waschtisch."
        },
        {
            original: Img9,
            thumbnail: Img9,
            description: "Das zweite Badezimmer mit Dusche, WC und Waschtisch."
        },
        {
            original: Img8,
            thumbnail: Img8,
            description: "Angenehm ruhen im Schlafzimmer mit Doppelbett"
        },
        {
            original: Img13,
            thumbnail: Img13,
            description: "Schlafzimmer im Untergeschoss."
        },
        {
            original: Img11,
            thumbnail: Img11,
            description: "Ein zweites Schlafzimmer mit zwei Einzelbetten im Giebel."
        },
        {
            original: Img10,
            thumbnail: Img10,
            description: "Von der Küche haben sie direkten Zugang zur Terrasse."
        },
        {
            original: Img14,
            thumbnail: Img14,
            description: "Große Terrasse."
        },
        {
            original: Img19,
            thumbnail: Img19,
            description: "Terrasse mit Esstisch und bequemer Sitzbank, ideal für gesellige Stunden..."
        },
        {
            original: Img18,
            thumbnail: Img18,
            description: "...und gemütliches Beisammensein."
        },
        {
            original: Img15,
            thumbnail: Img15,
            description: "Relaxen und entspannen in der eigenen Sauna."
        },
        {
            original: Img16,
            thumbnail: Img16,
            description: "Eine Sauna im Keller"
        },
        {
            original: Img17,
            thumbnail: Img17,
            description: "Nur 2 Gehminuten vom schönen Sandstrand entfernt."
        },
    ]
    return images;
}

export default getImageData