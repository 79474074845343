import background from "../assets/Sandwall.png";
import React from "react";
import {Container, Image, Tabs} from "react-bootstrap";
import {Tab} from "bootstrap";
import Unterkunft from "./Unterkunft";
import ImagesTab from "./ImagesTab";
import PriceTab from "./PriceTab";
import CalendarTab from "./CalendarTab";
import {Link} from "react-router-dom";
import IG_Logo from "../assets/ig_logo.png";

function Main() {
    return (
        <div>
            <div className="d-flex justify-content-center py-lg-5 backgroundHeader"
                 style={{backgroundImage: `url(${background})`}}>
                <div className="mx-auto">
                    <h1 className="navHeadline">Strandperle Sandwall</h1>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Tabs defaultActiveKey="unterkunft" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="unterkunft" title="Unterkunft">
                                <Unterkunft/>
                            </Tab>
                            <Tab eventKey="bilder" title="Bilder">
                                <ImagesTab/>
                            </Tab>
                            <Tab eventKey="preise" title="Preise">
                                <PriceTab/>
                            </Tab>
                            <Tab eventKey="kalender" title="Freie Zeiten">
                                <CalendarTab/>
                            </Tab>
                        </Tabs>
                        <div className="container">
                            <div className="row gx-0">
                                <div className="col-md-12 gx-0">
                                    <h4 className="">
                                        Buchen
                                    </h4>
                                    Sie haben interesse? <a href={"mailto:info@jost-foehrurlaub.de"}>Jetzt Buchen
                                    (info@jost-foehrurlaub.de)</a>
                                </div>
                            </div>
                            <div className="mt-4 pt-2 pb-3 footer">
                                <a href="https://www.instagram.com/jost_traumurlaub_auf_foehr/" target="_blank"  style={{textDecoration: "none",fontSize:"1.1rem"}}>
                                    <div className="mb-2">
                                        <Image src={IG_Logo} height={"40px"}
                                               className="ms-0 m-2"/><span>jost_traumurlaub_auf_foehr | Jetzt folgen!</span>
                                    </div>
                                </a>
                                <Link className="" to="/impressum">Impressum</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main