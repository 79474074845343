import Imageslider from "./Imageslider";
import {Container, Image} from "react-bootstrap";
import MapContainer from "./Map";
import React from "react";
import getImageData from "./ImageData";
import { SRLWrapper } from "simple-react-lightbox";

function createImageComponents() {
    return getImageData().map((image) => {
        return (
            <div className="row gx-0 pb-3">
                <div className={"col-md-1"}/>
                <div className="col-md-6 pe-5" align="center" style={{ position: "relative", maxHeight: "300px"}} >
                    <Image style={{maxHeight: "100%", cursor: "zoom-in"}}
                        src={image.original}
                        layout="fill"
                        rounded
                        fluid
                    />
                </div>
                <div className="col-md-4 gx-0">
                    {image.description}
                </div>
                <div className={"col-md-1"}/>
            </div>
        );
    });
}

function ImagesTab() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <Container className="gx-0">
                        <div className="row gx-0 d-none d-md-flex">
                            <div className={"col-md-1"}/>
                            <div className="col-md-6 gx-0">
                                <h4 className="me-lg-5">
                                    Bild
                                </h4>
                            </div>
                            <div className="col-md-4 gx-0">
                                <h4>
                                    Beschreibung
                                </h4>
                            </div>
                            <div className={"col-md-1"}/>
                        </div>
                        <SRLWrapper options={{settings:{disablePanzoom:true}}}>
                            {createImageComponents()}
                        </SRLWrapper>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default ImagesTab;