import React from "react";
import {Container, Image} from "react-bootstrap";
import '../App.css';
import background from "../assets/Suederstrasse.png";
import Imageslider from "./Imageslider";
import MapContainer from "./Map";
import {Link} from "react-router-dom";
import Icon1 from "../assets/icons/p_personen.gif";
import Icon8 from "../assets/icons/p_UNIRZ.gif";
import Icon2 from "../assets/icons/p_m2.gif";
import Icon9 from "../assets/icons/p_UHTAA.gif";
import Icon3 from "../assets/icons/p_schlafraeume.gif";
import Icon10 from "../assets/icons/p_LZMBL.gif";
import Icon4 from "../assets/icons/p_UENDR.gif";
import Icon11 from "../assets/icons/p_BWWWZ.gif";
import Icon5 from "../assets/icons/p_BPAPL.gif";
import Icon12 from "../assets/icons/p_BFRAB.gif";
import Icon6 from "../assets/icons/p_UTERR.gif";
import Icon13 from "../assets/icons/p_UBALK.gif";
import Icon7 from "../assets/icons/p_UGESP.gif";
import Icon14 from "../assets/icons/p_BWMAS.gif";


function Unterkunft() {
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <Imageslider/>
                                Die Unterkunft befindet sich im 1.OG.
                            </div>
                        </div>
                        <Container className="gx-0">
                            <div className="row gx-0">
                                <div className="col-md-8 gx-0">
                                    <h4 className="me-lg-5">
                                        Allgemeine Beschreibung
                                    </h4>
                                    <p className="pe-lg-5">
                                        Strandperle Sandwall<br/>
                                        Sandwall 20, Wyk<br/>
                                        Wohnen in bester Wohnlage, direkt an der Promenade nur 50m vom Strand entfernt.
                                        Einkaufsmöglichkeiten, Cafes, Kureinrichtungen und das Aqua Wyk sind in nächster Nähe.
                                        Die meisten Quartiere in zentraler Strandlage haben keinen Pkw Stellplatz.
                                        Zum Ein und Auschecken in Ihre Quartier können Sie aber direkt an das Grundstück von Sandwall 20 fahren und dann über den Gartenbereich von der Mühlenstraße zum Haupteingang zu gelangen.
                                        Kostenfreie Parkplätze stehen Ihnen in einiger Entfernung zur Verfügung (Am Wellenbad ca. 400 Meter und Heymannsparkplatz ca. 400 Meter entfernt).<br/>
                                        Die Wohnung ist im ersten Obergeschoss und hat einen herrlichen Meerblick.
                                        Das Quartier ist mit Terrakotta Fliesen ausgelegt.
                                        Sie erreichen das Quartier im 1.Obergeschoss rechts bequem über den Eingangsbereich am Sandwall.
                                        Die Wohnung ist ca.70 qm groß und bietet ein Schlafzimmer mit Doppelbett und ein Schlafzimmer mit zwei Einzelbetten.
                                        Beide Schlafzimmer liegen im hinteren Bereich der Wohnung mit Blick in den Garten, hier können sie ungestört schlafen.
                                        Um morgendliche Stauzeiten zu vermeiden stehen zwei Badezimmer mit Dusche, Badewanne, Toiletten, Waschtisch und Bidet zur Verfügung.
                                        Der Große Wohn-Essbereich mit integrierter Küche lässt keine Wünsche offen hier haben Sie einen herrlichen Blick auf die Nordsee.
                                        Die komfortable Einbauküche ist mit Mikrowelle, Backofen, Geschirrspüler ausgestattet.<br />
                                        Aufzug vorhanden.
                                    </p>
                                </div>
                                <div className="col-md-4 gx-0">
                                    <h4>
                                        Lage
                                    </h4>
                                    <table className="table table-borderless ">
                                        <thead>
                                        <th>Entfernungen (zirka)</th>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className="p-0">
                                                Zum Bäcker
                                            </td>
                                            <td className="p-0">
                                                100 m
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="p-0">
                                                zur Einkaufsmöglichkeit
                                            </td>
                                            <td className="p-0">
                                                200 m
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="p-0">
                                                zum Hafen
                                            </td>
                                            <td className="p-0">
                                                400 m
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="p-0">
                                                zum Kurzentrum
                                            </td>
                                            <td className="p-0">
                                                500 m
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="p-0">
                                                zum Meer
                                            </td>
                                            <td className="p-0">
                                                50 m
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="p-0">
                                                zum ÖPNV
                                            </td>
                                            <td className="p-0">
                                                400 m
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="p-0">
                                                zur Gaststätte
                                            </td>
                                            <td className="p-0">
                                                100 m
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="p-0">
                                                zum Wellenbad
                                            </td>
                                            <td className="p-0">
                                                500 m
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <MapContainer/>
                            </div>
                        </Container>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page-header">
                                    <h4>
                                        Ausstattung und Merkmale
                                    </h4>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <dl>
                                            <dt>
                                                Auszeichnung
                                            </dt>
                                            <dd>
                                                <p className="mb-1">Haustiere auf Anfrage gestattet*</p>
                                                <p className="mb-1">Nichtraucher</p>
                                            </dd>
                                            <dt>
                                                Außenbereich
                                            </dt>
                                            <dd>
                                                <p className="mb-1">Gartenmöbel</p>
                                                <p className="mb-1">Gartenmöbel (gemeinsch.)</p>
                                                <p className="mb-1">Strandkorb (gemeinsch.)</p>
                                            </dd>
                                            <dt>
                                                Komfort / Behaglichkeit
                                            </dt>
                                            <dd>
                                                <p className="mb-1">Fußbodenheizung teilweise</p>
                                            </dd>
                                            <dt>
                                                Küche / Haushalt
                                            </dt>
                                            <dd>
                                                <p className="mb-1">Backofen</p>
                                                <p className="mb-1">Geschirrspüler</p>
                                                <p className="mb-1">Mikrowelle</p>
                                                <p className="mb-1">Wäschetrockner</p>
                                                <p className="mb-1">Waschmaschine</p>
                                            </dd>
                                            <dt>
                                                Lage
                                            </dt>
                                            <dd>
                                                <p className="mb-1">Meerblick</p>
                                            </dd>
                                        </dl>
                                        <p style={{fontStyle: "italic"}}>* mit Extrakosten verbunden</p>
                                    </div>
                                    <div className="col-md-4">
                                        <dl>
                                            <dt>
                                                Räumlichkeiten
                                            </dt>
                                            <dd>
                                                <p className="mb-1">Fahrradraum (abschließbar, mit Stromanschluss)</p>
                                            </dd>
                                            <dt>
                                                Sanitär
                                            </dt>
                                            <dd>
                                                <p className="mb-1">Bidet</p>
                                                <p className="mb-1">Handtuchheizkörper</p>
                                            </dd>
                                            <dt>
                                                Service
                                            </dt>
                                            <dd>
                                                <p className="mb-1">Bettwäsche/Handtücher</p>
                                                <p className="mb-1">Endreinigung*</p>
                                            </dd>
                                            <dt>
                                                Unterhaltung / Kommunik.
                                            </dt>
                                            <dd>
                                                <p className="mb-1">Radio</p>
                                                <p className="mb-1">Satelliten-TV</p>
                                                <p className="mb-1">WLAN-Anschluss für Internetzugang</p>
                                            </dd>
                                            <dt>
                                                Wellness / Fitness
                                            </dt>
                                            <dd>
                                                <p className="mb-1">Sauna (gemeinsch.)*</p>
                                            </dd>
                                        </dl>
                                    </div>
                                    <div className="col-md-4">
                                        <table className="table table-striped">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <Image className="iconImage"
                                                           src={Icon1}
                                                    />
                                                </td>
                                                <td className="iconText noUni">
                                                    4
                                                </td>
                                                <td>
                                                    <Image className="iconImage"
                                                           src={Icon8}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    ✓
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Image className="iconImage"
                                                           src={Icon2}
                                                    />
                                                </td>
                                                <td className="iconText noUni">
                                                    70
                                                </td>
                                                <td>
                                                    <Image className="iconImage"
                                                           src={Icon9}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    ✓
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Image className="iconImage"
                                                           src={Icon3}
                                                    />
                                                </td>
                                                <td className="iconText noUni">
                                                    2
                                                </td>
                                                <td>
                                                    <Image className="iconImage"
                                                           src={Icon10}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    ✓
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Image className="iconImage"
                                                           src={Icon4}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    ✓
                                                </td>
                                                <td>
                                                    <Image className="iconImage"
                                                           src={Icon11}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    ✓
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Image className="iconImage"
                                                           src={Icon5}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    —
                                                </td>
                                                <td>
                                                    <Image className="iconImage"
                                                           src={Icon12}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    ✓
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Image className="iconImage"
                                                           src={Icon6}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    —
                                                </td>
                                                <td>
                                                    <Image className="iconImage"
                                                           src={Icon13}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    —
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Image className="iconImage"
                                                           src={Icon7}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    ✓
                                                </td>
                                                <td>
                                                    <Image className="iconImage"
                                                           src={Icon14}
                                                    />
                                                </td>
                                                <td className="iconText">
                                                    ✓
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <Container className="gx-0">
                                    <div className="row gx-0">
                                        <div className="col-md-8 gx-0">
                                            <h4 className="me-lg-5">
                                                Raumaufteilung
                                            </h4>
                                            <div className="row gx-0 pe-lg-5">
                                                <div className="col-md-6 gx-0">
                                                    <p>2 Schlafräume</p>
                                                    <p>1 Küchenzeile</p>
                                                    <p>1 Badezimmer mit Dusche</p>
                                                </div>
                                                <div className="col-md-6 gx-0">
                                                    <p>1 Wohnraum</p>
                                                    <p>1 Badezimmer und Wanne</p>
                                                    {/*<p>1 zusätzliches WC</p>*/}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 gx-0">
                                            <h4>
                                                Betten
                                            </h4>
                                            <p>2 Einzelbetten</p>
                                            <p>1 Doppelbett</p>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Unterkunft